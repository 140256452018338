<template>
<div class='install_main'>
  <div class="nav">
    <ul>
      <li v-for="(item,index) in headernav" :key="index">
        <i class="na-text" @click="this.$router.push(item.path)">{{item.text}}</i>
      </li>
    </ul>
  </div>

  <div class="main">
    <div class="top_live">
      <h2>下载开播工具，轻松开直播</h2>
      <div class="top_img">
        <div>
          <h3>电脑开播工具</h3>
          <p>功能强大，多场景开播，连麦等特色功能应有尽有</p>
          <img class="computer" src="../../static/imges/live/电脑.png" alt="">
          <img class="video" src="../../static/imges/live/现场直播.png" alt="">
          <el-button class="install" @click="toOBSinstall"><el-icon><download /></el-icon>立即下载</el-button>
        </div>
        <div>
          <h3>手机开播工具</h3>
          <p>手机开播,随时随地，想播就播</p>
          <img class="phone_er" src="../../static/imges/live/p285777.png" alt="">
        </div>
      </div>
    </div>
    <div class="bottom_live">
      <h2>轻松开播，只需三步</h2>
      <div>
        <img src="../../static/imges/live/bottom_live.png" alt="">
      </div>
    </div>

    <div class="btn">
      <el-button @click="dialogkaibo">立即开播</el-button>
      <p>不知道如何使用直播工具？<span @click="toCourse">查看教程</span></p>
    </div>
  </div>

  <div class="foot">
    <Footer/>
  </div>
</div>
<el-dialog v-model="TDialog" width="30%"
:close-on-click-modal="false"
  destroy-on-close
  title="开播信息">
  <div class="namebody">
    <!-- <p><span>推流地址：</span>{{areaTValue}}</p> -->
    <el-input v-model="createLiveValue.title" placeholder="请输入直播标题"></el-input>
    <div class="fm">
      <span>上传封面：</span>
      <el-upload
      :on-success="uploadSuccess"
      :before-upload="beforeUpload"
      :action="upLoadUrl"
      :data="upLoadData"
      :on-remove="handleRemove"

      list-type="picture-card"
      :limit="1"
      accept="jpg/jpeg/png"
    >
      <el-icon><plus /></el-icon>
    </el-upload>
    </div>

    <el-button
    @click="confirmReceiptBtn">发起直播</el-button>
    <!-- <a :href="toappUrl" v-show="toappUrl">打开客户端</a> -->
    <!-- <div class="toapp">
      <a :href="toappUrl">打开客户端</a>
    </div> -->
  </div>
</el-dialog>
</template>

<script>
import { reactive, ref } from 'vue-demi'
import Footer from '../index/Footer.vue'
import http from '../../api/http'
import { ElMessage } from 'element-plus'
import { getStore } from '../../tools/storage'
// import data from './pageOBS'
const headernav = [
  { icon: 'Iphone', text: '首页', path: '/' },
  { icon: 'Compass', text: '登录', path: '/loginandregister' },
  { icon: 'UserFilled', text: '移动端', path: '' }
]
// // 推流接口
// const pushFlow = () => {
//   return http.get('/mall-portal/aliyunLive/pushFlow')
// }
// 获取直播链接
const getStandardRoomJumpUrl = (data) => {
  return http.post('/mall-portal/aliyunLive/getStandardRoomJumpUrl', data)
}
// 创建状态为未开播的新直播
const createLiveRoom = (data) => {
  return http.post('/mall-portal/aliyunLive/createLiveRoom', data, true)
}
// var dom = document.querySelector('#toapp')
export default {
  name: '',
  data () {
    return {
      TDialog: ref(false), // 开播信息弹窗
      dir: null,
      fileList: [], // 封面上传
      upLoadUrl: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com',
      upLoadData: {
        key: null,
        policy: null,
        OSSAccessKeyId: null,
        success_action_status: 200, // 让服务端返回200，否则默认返回204。
        signature: null
      },

      createLiveValue: reactive({
        title: '', // 直播标题
        coverUrl: '' // 封面
      }), // 开播信息
      liveCreateData: reactive({}), // 创建直播成功存储数据
      toappUrl: ref('#/live/install')
    }
  },
  components: { Footer },
  methods: {
    toOBSinstall () {
      window.open('https://jingniu-zhibo.oss-cn-shanghai.aliyuncs.com/liveTool/aliyunclient_latest.exe', '_blank')
    },
    async dialogkaibo () { // 弹窗打开，地址生成
      this.TDialog = true
    },
    async confirmReceiptBtn (e) {
      this.createLiveValue.coverUrl = this.fileList[0].url
      if (this.createLiveValue.title !== '' && this.createLiveValue.coverUrl !== '') {
        console.log(this.createLiveValue)
        const data = {
          coverUrl: this.createLiveValue.coverUrl,
          title: this.createLiveValue.title
        }
        // console.log(data)
        const res = await createLiveRoom(data)
        console.log(res)
        if (res.data.code === 200) {
          this.liveCreateData = res.data.data.body
          const userId = getStore('usermessage') ? JSON.parse(getStore('usermessage')) : null
          const toapp = await getStandardRoomJumpUrl({
            bizId: this.liveCreateData.result.liveId,
            businessOrUser: 0,
            userId: userId ? userId.id : null
          })
          console.log(toapp)
          if (toapp.status === 200) {
            this.toappUrl = toapp.data.body.result.standardRoomJumpUrl
            this.toappUrl = this.toappUrl.replace('https://', 'aliyunclient://')
            setTimeout(() => {
              window.open(this.toappUrl)
            }, 200)
          }
        } else {
          this.createLiveValue.titl = ''
          this.fileList = []
          ElMessage.warning({
            message: res.data.msg
          })
        }
      } else {
        this.createLiveValue.titl = ''
        this.fileList = []
        ElMessage.warning({
          message: '请完善信息'
        })
      }

      // 一键复制功能
      // this.$copyText(this.areaTValue).then((e) => {
      //   console.log(e.text)
      // }).catch((err) => {
      //   console.log(err)
      // })
    },
    uploadSuccess (res, file) {
      // console.log(res, file)

      this.showFileList = true
      this.fileList.pop()
      const url = this.upLoadUrl + '/' + this.dir + '/' + file.name // 拼接oss路径
      this.fileList.push({ name: file.name, url: url })
      // this.emitInput(this.fileList[0].url)
    },
    beforeUpload (file) { // 主图上传图片触发方法
      // console.log(file)
      // this.fileName = file.uid + file.name.substring(file.name.lastIndexOf('.'))
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg'
      const extension2 = testmsg === 'png'
      const extension3 = testmsg === 'jpeg'
      const isLt2M = file.size / 1024 < 500 // 这里做文件大小限制
      if (!extension && !extension2 && !extension3) {
        ElMessage({
          message: '上传文件只能是 jpg、png、jpeg格式!',
          type: 'warning'
        })
        // this.handleRemove(file)
        return
      }
      if (!isLt2M) {
        ElMessage({
          message: '上传文件大小不能超过 500k!',
          type: 'warning'
        })
        // this.handleRemove(file)
        return
      }
      const param = {
        prefix: 'live/'
      }
      return new Promise((resolve, reject) => {
        http.get('mall-admin/aliyun/oss/policy', param)
          .then(res => {
            // console.log(res)
            this.dir = res.data.data.dir
            this.upLoadData.key = `${res.data.data.dir}/${file.name}`
            this.upLoadData.policy = res.data.data.policy
            this.upLoadData.OSSAccessKeyId = res.data.data.accessKeyId
            this.upLoadData.signature = res.data.data.signature
            this.upLoadUrl = res.data.data.host
            this.fileList.push(file)
            // console.log(this.fileList)
            resolve(true)
          }).catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    handleRemove (file) { // 删除主图
      console.log(file)
      // console.log(fileList)
      this.fileList = this.fileList.filter((item) => {
        return item.name !== file.name
      }) // 过滤方法
      // console.log(this.fileList)
    },
    toCourse () {
      // this.$router.push('/live/course')
      window.open('https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/admin/file/livegjpz.pdf')
    }
  },
  mounted () {

  },
  setup (props, ctx) {
    return {
      headernav
    }
  }
}
</script>
<style lang='scss'>
//@import ''; 引入公共css类
// @import '../../style/index.scss';
.install_main{
  background: #f2efed;
  .nav{
    background: #ffffff;
    height: 30px;
    ul{
      width: 1200px;
      margin: 0 auto;
      display: block;
      line-height: 30px;
      li{
        float: left;
        display: flex;
        // width: 56px;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
        .na-text{
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
        }
        // .na-ico{
        //   display: inline-block;
        //   box-sizing: border-box;
        //   width: 20px;
        //   height: 30px;
        //   // border: 1px solid black;
        //   line-height: 30px;
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   .el-icon{
        //     color: #666666;
        //     font-size: 18px;
        //   }
        // }
      }
    }
  }
  .main{
    width: 1181px;
    font-family: Microsoft YaHei;
    margin: 0 auto;
    // padding: ;
    margin-bottom: 50px;
    height: 1682px;
    background: url('../../static/imges/Livebg.png') no-repeat;
    display: flex;
    flex-flow: column;
    .top_live{
      height: 42%;
      box-sizing: border-box;
      padding: 60px 50px;
      display: flex;
      flex-flow: column;
      // justify-content: center;
      align-items: center;
      h2{
        font-size: 50px;
        font-weight: 400;
        color: #000000;
        line-height: 70px;
      }
      .top_img{
        width: 100%;
        display: flex;
        align-items: center;
        padding: 50px 0;
        div{
          flex: 1;
          display: flex;
          flex-flow: column;
          justify-content: center;
          text-align: center;
          position: relative;
          // line-height: 50px;
          .phone_er{
            margin-bottom: 40px;
          }
          h3{
            font-size: 27px;
            font-weight: 300;
            color: #000000;
          }
          p{
            margin: 30px 0;
            font-weight: 300;
            color: #666666;
            font-size: 13px;
          }
          .computer{
            width: 278px;
            height: 240px;
          }
          .video{
            width: 94px;
            height: 78px;
            position: absolute;
            left: 42%;
            top: 40%;
          }
          img{
            margin: 0 auto;
          }
          .install{
            margin: 0 auto;
            width: 195px;
            height: 40px;
            color: #ffffff;
            background: #000000;
            border-radius: 20px;
            margin-top: 20px;
            align-items: center;
            .el-icon{
              font-size: 16px;
            }
          }
        }
      }
    }
    .bottom_live{
      height: 42%;
      box-sizing: border-box;
      padding: 50px;
      display: flex;
      flex-flow: column;
      // justify-content: center;
      align-items: center;
      h2{
        font-size: 50px;
        font-weight: 400;
        color: #000000;
        line-height: 70px;
      }
      div{
        padding: 50px 0;
      }
    }
    .btn{
      flex: 1;
      display: flex;
      flex-flow: column;
      margin: 0 auto;
      text-align: center;
      justify-content: center;
      .el-button{
        width: 550px;
        height: 60px;
        background: #000000;
        border-radius: 10px;
        span{
          font-size: 33px;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
      p{
        margin: 20px 0;
        font-size: 21px;
        font-weight: 400;
        color: #666666;
        line-height: 39px;
        span{
          color: #FD6B00;
          cursor: pointer;
        }
      }
    }
  }
  .foot{
    background: #280E01;
  }
}
.el-overlay{
  .el-overlay-dialog{
    .el-dialog{
      .el-dialog__body{
        .namebody{
          display: flex;
          // flex-wrap: wrap;
          flex-flow: column;
          .fm{
            margin: 20px 0;
            display: flex;
            // align-items: center;
          }
          .toapp{
            // flex: 1;
            width: 100px;
            height: 50px;
            display: inline-block;
            text-align: center;
            margin: 0 auto;
            color: #666666;
            a{
              line-height: 50px;
              color: #FD6B00;
            }
          }
        }
      }
    }
  }
}
</style>
